import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";

import "@fontsource/inter";
import * as Sentry from "@sentry/react";
import { logVersion } from "./views/Vocoda/utils/appVersion";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://f54de96bf2e33a4ea945767d5163a507@o4508013773258752.ingest.de.sentry.io/4508092710649936",
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: function (integrations) {
      // integrations will be all default integrations
      return integrations.filter(function (integration) {
        return integration.name !== "Dedupe";
      });
    },
  });
}

logVersion();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
